<script lang="ts" setup>
import type { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types/v4'
import { Pagination } from 'swiper/modules'
import type { text } from '~/interfaces/blocks.interface'
const { find } = useStrapi()
const { locale } = useI18n()
const config = useRuntimeConfig()

defineProps<{
  content: text
}>()

const { data } = await useCachedAsyncData<Strapi4ResponseMany>(
  `articles_last_3`,
  () =>
    find('articles', {
      populate:
        'article_category, background, background_mobile, seo, seo.metaImage, seo.metaSocial, seo.metaSocial.image',
      locale: locale.value,
      sort: ['release:desc', 'id'],
      pagination: {
        pageSize: 3,
      },
    }),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)
</script>

<template>
  <section class="last-news">
    <UContainer>
      <div class="flex items-center justify-between">
        <div class="self-start">
          <h2 class="text-h3 text-primary text-left">{{ content.title }}</h2>
          <div class="mt-10 h-2 w-62 bg-secondary-light lg:mt-24"></div>
        </div>
        <AkButton
          class="max-lg:hidden lg:-mt-24"
          :button="content.button"
          v-if="content.button"
        />
      </div>
      <div class="mt-40">
        <Swiper
          class="relative"
          :modules="[Pagination]"
          :breakpoints="{
            1280: { slidesPerView: 3, enabled: false },
          }"
          :slides-per-view="1"
          space-between="20"
          :pagination="{
            el: '.last-news__pagination',
            type: 'bullets',
          }"
        >
          <SwiperSlide v-for="item in data.data" class="!h-[480px]">
            <nuxt-link
              :to="`${$t('routes.article')}/${item.attributes.article_category.data.attributes.slug}/${item.attributes.slug}`"
              class="group relative z-10 flex h-full flex-col rounded-10 bg-cover p-40"
              :data-twic-background="
                item.attributes.background.data
                  ? useGetTwicUrlBackground(item.attributes.background)
                  : ''
              "
            >
              <div
                class="absolute bottom-0 left-0 right-0 top-0 -z-10 rounded-10 bg-black/50 group-hover:hidden"
              ></div>
              <div
                class="absolute bottom-0 left-0 right-0 top-0 -z-10 origin-bottom-left scale-0 rounded-10 bg-[linear-gradient(to_top,rgba(0,190,178,0.4),rgba(0,190,178,0))] transition duration-300 group-hover:scale-100"
              ></div>
              <p
                class="text-primary absolute right-40 top-20 rounded-full bg-white px-22 py-4 text-14 font-bold uppercase leading-20"
              >
                {{ item.attributes.article_category.data.attributes.name }}
              </p>
              <div class="mt-auto">
                <p class="text-h4 text-blue-200">
                  {{ item.attributes.title }}
                </p>
                <AkText
                  v-if="item.attributes.resume"
                  class="last-news__text"
                  :text="item.attributes.resume"
                />
              </div>
            </nuxt-link>
          </SwiperSlide>
          <div class="last-news__pagination"></div>
        </Swiper>
      </div>
      <AkButton
        class="mt-32 justify-center max-lg:flex max-lg:w-full lg:hidden"
        :button="content.button"
        v-if="content.button"
      />
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.last-news {
  &__text {
    @apply mt-16 text-18 leading-28 tracking-[0.18px] text-white;

    :deep(p),
    :deep(div) {
      @apply text-18 leading-28 tracking-[0.18px] lg:truncate lg:group-hover:whitespace-normal;
    }
  }

  :deep(.last-news__pagination) {
    @apply mt-32 flex justify-center gap-26 lg:hidden;

    .swiper-pagination-bullet {
      @apply m-0 h-10 w-10 rounded-full bg-[#D9D9D9] opacity-100;

      &-active {
        @apply bg-secondary-light;
      }
    }
  }
}
</style>
